import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Navigation from '../components/navigation';
import ContactForm from '../components/ContactForm';

import BlockContent from "@sanity/block-content-to-react"

const TrainingPlans = ({ data }) => {
  const trainingPlan = data.allSanityTrainingPlans.edges[0].node;


  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case "h1":
        return (
          <div >
            <h1 >{children}</h1>
          </div>
        )
      case "h2":
        return (
          <div >
            <h2 >{children}</h2>
          </div>
        )
      case "h3":
        return (
          <div >
            <h3 >{children}</h3>
          </div>
        )
      case "h4":
        return (
          <div >
            <h4 >{children}</h4>
          </div>
        )
      case "h5":
        return (
          <div >
            <h5 >{children}</h5>
          </div>
        )
      case "h6":
        return (
          <div >
            <h6 >{children}</h6>
          </div>
        )
      case "blockquote":
        return (
          <div>
            <blockquote>{children}</blockquote>
          </div>
        )
      default:
        return (
          <div>
            <p>{children}</p>
          </div>
        )
    }
  }

  const pageWrapperStyles = css`
    max-width: 1200px;
    margin: var(--size-4) var(--size-4) var(--size-8);

    @media (min-width: 900px) {
      margin: var(--size-8) var(--size-8);
    }

    @media (min-width: 1248px) {
      margin: var(--size-11) auto var(--size-11);
    }
  `;

  const trainingPlansWrapperStyles = css`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  `;

  const trainingPlansImageStyles = css`
    width: 100%;
    margin-bottom: var(--size-8);
    box-shadow: 16px 16px 24px 0 rgba(51, 51, 51, 0.16);
    order: 1;

    @media (min-width: 800px) {
      width: 50%;
      order: 2;
    }

    @media (min-width: 1248px) {
      margin-bottom: 0;
    }
  `;

  const trainingPlansTextStyles = css`
    width: 100%;
    order: 2;

    @media (min-width: 800px) {
      width: calc(50% - var(--size-10));
      margin-right: var(--size-10);
      order: 1;
    }
  `;

  return (
    <Layout>
      <SEO
        title='Personalised Training Plans'
        description='Personalised training plans. On your schedule, fit around your life.'
      />
      <Navigation solid />
      <div css={pageWrapperStyles}>
        <h1
          css={css`
            font-size: var(--title-large);
          `}
        >
          {trainingPlan.title}
          {/* Personalised Training Plans */}
        </h1>
        <div css={trainingPlansWrapperStyles}>
          <div              css={trainingPlansTextStyles}
>

<BlockContent
              blocks={trainingPlan._rawTrainingPlansInfo}
              serializers={{
                types: { block: blockRenderer },
              }}
            />
</div>

          <div css={trainingPlansImageStyles}>
            <Img
              fluid={{
                ...trainingPlan.trainingPlansImage.asset.fluid,
                sizes: '(min-width: 1200px) 1150px, 100vw',
              }}
            />
          </div>
        </div>
      </div>

      <ContactForm />
    </Layout>
  );
};

export default TrainingPlans;

export const pageQuery = graphql`
  query TrainingPlansQuery {
    site {
      siteMetadata {
        title
      }
    }

    
  allSanityTrainingPlans {
    edges{
      node{
    title
    trainingPlansImage {
      asset {
        fluid {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    _rawTrainingPlansInfo
  }
    }
  }

  }
`;
